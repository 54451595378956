import React, { Component } from "react";
import { Grid, Icon, Label, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faSearch } from "@fortawesome/pro-solid-svg-icons";

import { ThumbToggle, InlineInputIcon, CustomConfirm, Pin, Snooze, FileDropDown } from "../../components";
import { getFilesButton, pinLogAttachmentList } from "../../util/common";
import { renderMedia, renderReadyStatus, APPOINTMENT_STATUSES } from "./common";
import { getPriceForDisplay } from "./util";
import MechanicNote from "./MechanicNote";
import InterventionElements from "./InterventionElements";
import Can from "../Can";
import { ROLES } from "../Users/roles";
import { DMS } from "../DMSv3/util";

const renderInterventionEditableField = ({ index, field, placeholder, value, onUpdate, appointment_status_identifier }) => (
  <Can I="update" the="interventions">
    <InlineInputIcon
      type="textarea"
      placeholder={placeholder}
      value={value ? value.trim() : ""}
      icon="save"
      textAreaWidth="81%"
      disabled={appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED}
      iconConfig={{
        style: {
          marginTop: "1em!important",
        },
      }}
      onSave={value => onUpdate({ index, type: field, value })}
    />
  </Can>
);

const getInterventionMedia = ({ appointment, index, dashboardConfig, remarkAttachments }) => {
  let images = [];
  let videos = [];
  let files = [];

  if (remarkAttachments) {
    for (const attachment of remarkAttachments) {
      if (/\.(gif|jpg|jpeg|png)$/i.test(attachment.name)) {
        images.push(attachment);
      } else if (/\.(avi|mov|wmv|amv|mpeg|mkv)$/i.test(attachment.name)) {
        if (dashboardConfig["video_enabled"]) videos.push(attachment);
      } else {
        files.push(attachment);
      }
    }
  }

  if (appointment.final_check_images) {
    for (const image of appointment.final_check_images) {
      if (image.active && image.intervention_index === index) {
        images.push(image);
      }
    }
  }

  if (dashboardConfig["video_enabled"] && appointment.final_check_videos) {
    for (const video of appointment.final_check_videos) {
      if (video.active && video.intervention_index === index) {
        videos.push(video);
      }
    }
  }

  return { images, videos, files };
};

const renderList = (
  location,
  appointment_status_identifier,
  dms_price_enabled,
  handlePriceUpdate,
  isCustomerCommunicationVisible,
  vat,
  includeVAT,
  appointment,
  dashboardConfig,
  interventions,
  onMediaClick,
  onAnswerUpdate,
  onMediaToggle,
  handleOpenConfirmDeleteIntervention,
  isReadOnly,
  isPriceEditable,
  handleToggleInterventionElements,
  showInterventionElementsID,
  currentUser,
  locationDmsId,
  interventionsIdsConnectedToChecks,
  interventionIdsToMagnify,
  onHandleUpdateInterventionIdsToMagnify,
  t
) => {
  if (!interventions) {
    return <p className="NoInterventions">{t("no_interventions").message || "No interventions"}</p>;
  }

  const renderInterventionElementsToggleBtn = intervention => {
    const hasContent = intervention.elements?.length > 0 || intervention.internal_note;

    if (!interventions.some(i => i.elements?.length > 0 || i.internal_note)) return null;

    return (
      <div className="intervention-elements-toggle-container">
        <FontAwesomeIcon
          style={{ visibility: `${hasContent ? "visible" : "hidden"}` }}
          onClick={() => hasContent && handleToggleInterventionElements(intervention.id)}
          icon={showInterventionElementsID === intervention.id ? faCaretDown : faCaretRight}
          className="intervention-elements-toggle-btn"
        />
      </div>
    );
  };

  interventions.forEach(i =>
    i.elements?.sort((a, b) => {
      if (!a.dms_nr && !b.dms_nr) return a.id - b.id;

      if (a.parent_dms_nr === b.parent_dms_nr) return a.dms_nr.localeCompare(b.dms_nr, undefined, { numeric: true, sensitivity: "base" });

      if ((a.parent_dms_nr === "") !== (b.parent_dms_nr === "")) {
        if (a.parent_dms_nr === b.dms_nr) return 1;
        else if (b.parent_dms_nr === a.dms_nr) return -1;
        return (a.parent_dms_nr || a.dms_nr).localeCompare(b.parent_dmsn_nr || b.dms_nr, undefined, { numeric: true, sensitivity: "base" });
      }

      return a.parent_dms_nr.localeCompare(b.parent_dmsn_nr, undefined, { numeric: true, sensitivity: "base" });
    })
  );

  return interventions
    .sort((a, b) => {
      if (a.dms_nr && b.dms_nr) {
        if (a.dms_nr !== b.dms_nr) return a.dms_nr.localeCompare(b.dms_nr, undefined, { numeric: true, sensitivity: "base" });
      } else if (!a.dms_nr && b.dms_nr) return 1;
      else if (a.dms_nr && !b.dms_nr) return -1;

      return a.id - b.id;
    })
    .map((i, k) => {
      let isEditable = !isCustomerCommunicationVisible && i.is_local && !i.mechanic_fixed && !isReadOnly;

      const workStarted = ![
        APPOINTMENT_STATUSES.NEW_CAR,
        APPOINTMENT_STATUSES.HANDLE_CHECK_IN,
        APPOINTMENT_STATUSES.CAR_IN_SHOP,
        APPOINTMENT_STATUSES.CHECK_IN_DONE,
      ].includes(appointment.appointment_status_identifier);

      if (!i.is_local && location.keep_importing_after_work_started && workStarted) isPriceEditable = false;

      let { images, videos, files } = getInterventionMedia({
        appointment,
        index: k,
        dashboardConfig,
        remarkAttachments: [...(i.checkin_attachments || []), ...(i.diagnose_overview_attachments || [])],
      });
      const hasDescription = !!i.description;

      const isCustomerVisibilityEditable =
        ![ROLES.SUPERVISOR, ROLES.MANUFACTURER, ROLES.MECHANIC].includes(currentUser.role_id) &&
        appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CANCELED;

      return (
        <Grid className="AppointmentChecklistGroup__item" key={k}>
          <Grid.Column computer={4} widescreen={5} className={`${isEditable ? "AppointmentChecklistGroup__item__question" : ""} intervention-title`}>
            {renderInterventionElementsToggleBtn(i)}

            {isEditable && (
              <div className="DeleteIntervention">
                <Can I="update" the="interventions">
                  <Label style={{ display: "inline-block", alignSelf: "center" }}>
                    <Icon
                      className="-cursor-pointer -no-margin"
                      color={i.visible_to_customer ? "green" : "red"}
                      name={i.visible_to_customer ? "eye" : "low vision"}
                      onClick={() => {
                        onAnswerUpdate({
                          type: "visible_to_customer",
                          value: !i.visible_to_customer,
                          index: k,
                        });
                      }}
                    />
                  </Label>
                </Can>
                <Can I="delete" the="interventions">
                  <Label>
                    <Icon className="-cursor-pointer-trash" name="trash" onClick={() => handleOpenConfirmDeleteIntervention(i.id)} />
                  </Label>
                </Can>
                {!i.is_keyloop && locationDmsId !== DMS.KEYLOOP_JOBS ? (
                  renderInterventionEditableField({
                    index: k,
                    field: "title",
                    placeholder: t("title").message,
                    value: i.title,
                    onUpdate: onAnswerUpdate,
                    appointment_status_identifier: appointment_status_identifier,
                  })
                ) : (
                  <span>{i.title}</span>
                )}
              </div>
            )}
            {!isEditable && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Can I="update" the="interventions">
                  <Label style={{ display: "inline", alignSelf: "center" }}>
                    <Icon
                      className={isCustomerVisibilityEditable ? "-cursor-pointer" : ""}
                      color={i.visible_to_customer ? "green" : "red"}
                      name={i.visible_to_customer ? "eye" : "low vision"}
                      onClick={() => {
                        isCustomerVisibilityEditable &&
                          onAnswerUpdate({
                            type: "visible_to_customer",
                            value: !i.visible_to_customer,
                            index: k,
                          });
                      }}
                    />
                  </Label>
                </Can>
                <strong style={{ alignSelf: "center", marginLeft: "5px" }}>{i.title}</strong>
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={includeVAT ? 6 : 7} className="AppointmentChecklistGroup__item__question_remark">
            {hasDescription &&
              isEditable &&
              renderInterventionEditableField({
                index: k,
                field: "description",
                placeholder: t("description").message,
                value: i.description,
                onUpdate: onAnswerUpdate,
              })}
            {hasDescription && !isEditable && <span>{i.description}</span>}
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={1} className="intervention-price-container no-vat-price">
            {dms_price_enabled && (
              <div className="InlineInput_Icon">
                {!includeVAT && i.check_in_result && i.check_in_result.price !== i.price && (
                  <Popup
                    content={
                      (t("original_price_during_checkin").message || "Original price agreed by the customer during online Check-in") +
                      ": " +
                      i.check_in_result.price +
                      " €"
                    }
                    trigger={<Icon color="red" style={{ display: "inline" }} className="-cursor-pointer-no-color" name="exclamation circle" />}
                  />
                )}
                <Can I="update" the="interventions">
                  <InlineInputIcon
                    type="price"
                    value={i.price}
                    icon="save"
                    leftIcon="euro"
                    onSave={value => {
                      if (!value) {
                        value = 0.0;
                      }

                      const update = {
                        index: k,
                        value,
                      };

                      handlePriceUpdate(update);
                    }}
                    config={{
                      // type: "number",
                      // step: "0.01",
                      placeholder: "0.00",
                      // min: "0.00",
                      // max: "9999999999.99",
                      style: {
                        width: "100%",
                        paddingBottom: "0px",
                        paddingRight: "0px",
                      },
                      disabled: isCustomerCommunicationVisible || !isPriceEditable || i.is_keyloop,
                    }}
                    displayValueFunc={getPriceForDisplay}
                  />
                </Can>
              </div>
            )}
          </Grid.Column>
          {includeVAT && (
            <Grid.Column width={1} className="intervention-price-container vat-price">
              {dms_price_enabled && (
                <div className="InlineInput_Icon">
                  {i.check_in_result && i.check_in_result.price !== i.price && (
                    <Popup
                      content={
                        (t("original_price_during_checkin").message || "Original price agreed by the customer during online Check-in") +
                        ": " +
                        i.check_in_result.price +
                        " €"
                      }
                      trigger={<Icon color="red" style={{ display: "inline" }} className="-cursor-pointer-no-color" name="exclamation circle" />}
                    />
                  )}
                  <Can I="update" the="interventions">
                    <InlineInputIcon
                      type="price"
                      value={i.price * (1 + vat / 100)}
                      icon="save"
                      leftIcon="euro"
                      onSave={value => {
                        if (!value) {
                          value = 0.0;
                        }

                        value = value / (1 + vat / 100);

                        const update = {
                          index: k,
                          value,
                        };

                        handlePriceUpdate(update);
                      }}
                      config={{
                        // type: "number",
                        // step: "0.01",
                        placeholder: "0.00",
                        // min: "0.00",
                        // max: "9999999999.99",
                        style: {
                          width: "100%",
                          paddingBottom: "0px",
                          paddingRight: "0px",
                          color: "#000",
                        },
                        disabled: isCustomerCommunicationVisible || !isPriceEditable || i.is_keyloop,
                      }}
                      displayValueFunc={getPriceForDisplay}
                    />
                  </Can>
                </div>
              )}
            </Grid.Column>
          )}
          <Grid.Column
            computer={3}
            widescreen={2}
            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
            textAlign="right"
            className="AppointmentChecklistGroup__item__modifiers -no-padding-right -no-padding-left -text-right"
          >
            {(images && images.length > 0) || (videos && videos.length > 0) || (files && files.length > 0) ? (
              <Label
                color="teal"
                style={{
                  width: "auto",
                }}
                onClick={() => {
                  onMediaToggle(k);
                }}
              >
                <Icon name="images" /> {dashboardConfig["video_enabled"] && videos ? videos.length + images.length + files.length : images.length + files.length}
              </Label>
            ) : (
              ""
            )}

            {interventionsIdsConnectedToChecks.includes(i.id) && (
              <Label onClick={() => onHandleUpdateInterventionIdsToMagnify(i.id)}>
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: "1.4em", color: interventionIdsToMagnify.includes(i.id) ? "green" : "" }} />
              </Label>
            )}

            {dashboardConfig["schedule_enabled"] && (
              <Snooze
                intervention={i}
                appointment={appointment}
                snoozeLog={i.snooze_history}
                onSnooze={snoozeInfo =>
                  onAnswerUpdate({
                    index: k,
                    questionId: i.id,
                    type: "snooze_history",
                    shouldSave: false,
                    value: snoozeInfo,
                  })
                }
                onDelete={() =>
                  onAnswerUpdate({
                    index: k,
                    questionId: i.id,
                    type: "snooze_history",
                    shouldSave: false,
                    value: [],
                  })
                }
              />
            )}

            {dashboardConfig["pin_visible"] && (
              <Pin
                intervention={i}
                appointment={appointment}
                pinLog={i.pin_history}
                onPin={pinLog =>
                  onAnswerUpdate({
                    index: k,
                    questionId: i.id,
                    type: "pin_history",
                    shouldSave: false,
                    value: pinLog,
                  })
                }
                onDelete={() => {
                  onAnswerUpdate({
                    index: k,
                    questionId: i.id,
                    type: "pin_history",
                    shouldSave: false,
                    value: [],
                  });
                }}
              />
            )}

            <ThumbToggle
              status={i.customer_ok}
              disabled={i.mechanic_fixed || isCustomerCommunicationVisible || isReadOnly}
              onClick={() =>
                onAnswerUpdate({
                  type: "customer_ok",
                  value: !i.customer_ok,
                  index: k,
                })
              }
            />

            <span className="AnswerReadyStatus">{i.customer_ok && renderReadyStatus(i.mechanic_fixed)}</span>
            {/* {(!isFinalCheckDone || !i.customer_ok) && <Icon style={{ marginRight: '5px' }} />}                                 */}
          </Grid.Column>

          {(i.elements?.some(el => el.intervention_id === showInterventionElementsID) || (i.internal_note && showInterventionElementsID === i.id)) && (
            <InterventionElements intervention={i} elements={i.elements || []} includeVAT={includeVAT} vat={vat} dms_price_enabled={dms_price_enabled} />
          )}

          {i.mechanic_notes && !isCustomerCommunicationVisible && <MechanicNote note={i.mechanic_notes} />}
          {i.pin_history?.some(log => log.visible_important_items) && (
            <div className="ImportantItems-notes">
              {i.pin_history.map(
                log =>
                  log.visible_important_items && (
                    <Grid.Row className="note-subline-row" key={log.id}>
                      <Grid.Column stretched>
                        <div className={`note-subline ${log.keep_parts ? "keep-parts" : ""}`}>
                          {log.keep_parts && (t("keep_parts_prefix").message || "KEEP PARTS") + " "}
                          {log.note_attachments?.length > 0 && (
                            <FileDropDown
                              resource="pin"
                              files={pinLogAttachmentList(log)}
                              trigger={getFilesButton({ count: log.note_attachments.length })}
                              showPreview
                              showCount={false}
                              showIcon={true}
                              disableUpload
                            />
                          )}
                          {log.note}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  )
              )}
            </div>
          )}

          {i.is_declined_by_customer && (
            <Grid.Row className="declined-intervention-container">
              <Grid.Column stretched className="declined-intervention">
                {t("REMOVED_by_customer").message || "REMOVED by customer"}
              </Grid.Column>
            </Grid.Row>
          )}

          {i.mediaVisible && renderMedia(isCustomerCommunicationVisible, appointment, images, videos, onMediaClick, null, false, files)}
        </Grid>
      );
    });
};

class AppointmentInterventions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interventions: this.props.items,
      interventionID: null,
      isOpenConfirmInterventionDelete: false,
      showInterventionElementsID: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.interventions) {
      this.setState({ interventions: nextProps.items });
    }
  }

  handleAnswerUpdate = update => {
    let interventions = this.state.interventions;

    if (interventions && interventions[update.index]) {
      interventions[update.index][update.type] = update.value;
      if (update.type === "snooze_history") interventions[update.index].snoozed = update.value.length > 0;
      else if (update.type === "pin_history") interventions[update.index].pinned = update.value.length > 0;
      this.setState({ interventions }, () => this.props.onAnswerUpdate(interventions[update.index]));
    }
  };

  handleMediaToggle = interventionIndex => {
    let interventions = this.state.interventions;
    interventions[interventionIndex].mediaVisible =
      typeof interventions[interventionIndex].mediaVisible === "undefined" ? true : !interventions[interventionIndex].mediaVisible;

    this.setState({ interventions });
  };

  handlePriceUpdate = update => {
    let interventions = this.state.interventions;
    if (interventions && interventions[update.index]) {
      interventions[update.index]["price"] = update.value;
      interventions[update.index]["dms_price_edited"] = true;
      this.setState({ interventions }, () => this.props.onAnswerUpdate(interventions[update.index]));
    }
  };

  handleOpenConfirmDeleteIntervention = id => {
    this.setState({ isOpenConfirmInterventionDelete: true, interventionID: id });
  };

  handleCloseConfirmDeleteIntervention = () => {
    this.setState({ isOpenConfirmInterventionDelete: false, interventionID: null });
  };

  handleDeleteIntervention = id => {
    if (!id) return;
    this.props.onInterventionDelete(id);
    this.handleCloseConfirmDeleteIntervention();
  };

  handleToggleInterventionElements = showInterventionElementsID => {
    this.setState({ showInterventionElementsID: showInterventionElementsID !== this.state.showInterventionElementsID ? showInterventionElementsID : null });
  };

  render() {
    let {
      location,
      appointment_status_identifier,
      dms_price_enabled,
      isCustomerCommunicationVisible,
      vat,
      includeVAT,
      appointment,
      dashboardConfig,
      onMediaClick,
      onShowAddInterventionClick,
      isDeletingIntervention,
      isReadOnly,
      isPriceEditable,
      currentUser,
      locationDmsId,
      interventionsIdsConnectedToChecks,
      interventionIdsToMagnify,
      onHandleUpdateInterventionIdsToMagnify,
      t,
    } = this.props;

    return (
      <div className="AppointmentChecklistGroup AppointmentInterventions">
        <div className="AppointmentLists__list-title">
          <Grid>
            <Grid.Row style={{ paddingTop: "0em", paddingBottom: "0em" }}>
              <Grid.Column computer={4} widescreen={5}>
                <Can I="add" the="interventions">
                  {!isCustomerCommunicationVisible && !isReadOnly && (
                    <div className="add-intervention-icon" onClick={onShowAddInterventionClick}>
                      <Icon name="plus" color="white" />
                    </div>
                  )}
                </Can>
                <h1>{t("Interventions").message}</h1>
              </Grid.Column>
              <Grid.Column width={includeVAT ? 6 : 7}>
                <span>{t("description").message || "Description"}</span>
              </Grid.Column>
              <Grid.Column className="-no-padding-left" width={1}>
                <span>{t("quantity").message || "Quantity"}</span>
              </Grid.Column>
              <Grid.Column className="no-vat-price" width={1}>
                {dms_price_enabled && <span>{t("ex_vat").message || "Ex VAT"}</span>}
              </Grid.Column>
              {includeVAT && (
                <Grid.Column className="vat-price" width={1}>
                  {dms_price_enabled && <span>{t("in_vat").message || "In VAT"}</span>}
                </Grid.Column>
              )}
              <Grid.Column computer={3} widescreen={2} className="-no-padding-right">
                <div className="-pull-right CustomerSmile">
                  <Icon name="smile" className="-no-click -no-margin" style={{ paddingTop: "0.3em" }} />
                  <Icon name="car" className="-no-click" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {renderList(
          location,
          appointment_status_identifier,
          dms_price_enabled,
          this.handlePriceUpdate,
          isCustomerCommunicationVisible,
          vat,
          includeVAT,
          appointment,
          dashboardConfig,
          this.state.interventions,
          onMediaClick,
          this.handleAnswerUpdate,
          this.handleMediaToggle,
          this.handleOpenConfirmDeleteIntervention,
          isReadOnly,
          isPriceEditable,
          this.handleToggleInterventionElements,
          this.state.showInterventionElementsID,
          currentUser,
          locationDmsId,
          interventionsIdsConnectedToChecks,
          interventionIdsToMagnify,
          onHandleUpdateInterventionIdsToMagnify,
          t
        )}
        <CustomConfirm
          type="danger"
          isOpen={this.state.isOpenConfirmInterventionDelete}
          handleCancel={this.handleCloseConfirmDeleteIntervention}
          handleConfirm={() => this.handleDeleteIntervention(this.state.interventionID)}
          isLoading={isDeletingIntervention}
          confirmText={t("yes").message || "YES"}
          cancelText={t("no").message || "NO"}
          confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        />
      </div>
    );
  }
}

export default withTranslation()(AppointmentInterventions);
