import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Checkbox } from "semantic-ui-react";

class Configuration extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    const { dealer, handleChange } = this.props;

    handleChange({ ...dealer, [name]: checked });
  };

  render() {
    const { dealer, t } = this.props;

    return (
      <div>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("can_add_users").message || "Can add users"}</label>
            <Checkbox
              defaultChecked={dealer.can_add_users}
              name="can_add_users"
              label={t("can_add_users").message || "Can add users"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>DBB</label>
            <Checkbox defaultChecked={dealer.dbb_enabled} name="dbb_enabled" label="DBB" onChange={this.handleCheckboxChange} />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("custom_domain").message || "Custom Domain"}</label>
            <Checkbox
              defaultChecked={dealer.custom_domain_enabled}
              name="custom_domain_enabled"
              label={t("custom_domain").message || "Custom Domain"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("car_return_time").message || "Car Return Time"}</label>
            <Checkbox
              defaultChecked={dealer.enable_car_ready}
              name="enable_car_ready"
              label={t("car_return_time").message || "Car Return Time"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("advised_critical_history").message || "Advised and critical history"}</label>
            <Checkbox
              defaultChecked={dealer.advised_critical_history_enabled}
              name="advised_critical_history_enabled"
              label={t("advised_critical_history").message || "Advised and critical history"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(Configuration);
