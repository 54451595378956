import React, { Component } from "react";
import { Icon, Form, Segment, Header, Label, Checkbox } from "semantic-ui-react";

import { isAdmin } from "../../util/common";
import { DMS } from "../../modules/DMSv3/util";
import UserInput from "../UserInput";
import MCCConfiguration from "./MCCConfiguration.js";
import PlanitConfiguration from "./PlanitConfiguration";

import Service from "./service.js";

class DetailSettings extends Component {
  state = {
    allSeasonThresholdError: false,
    winterThresholdError: false,
    summerThresholdError: false,
    truckTyreThresholdError: false,
    loadingPlanitPlanningEstablishmentOptions: false,
    planitPlanningEstablishmentOptions: [],
  };

  componentDidMount() {
    const { allowSave, ...validationValues } = this.validatevalidationValues();

    if (this.props.dealer.planit_planning_enabled) this.getPlanitPlanningEstablishmentIDs();

    this.setState({ ...validationValues });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.id !== this.props.location.id) {
      const { allowSave, ...validationValues } = this.validatevalidationValues();
      this.setState({ ...validationValues });
    } else if (this.props.dealer.planit_planning_enabled && prevProps.dealer.id !== this.props.dealer.id) this.getPlanitPlanningEstablishmentIDs();
  }

  getPlanitPlanningEstablishmentIDs = () => {
    this.setState({ loadingPlanitPlanningEstablishmentOptions: true }, () => {
      Service.getPlanitPlanningEstablishmentIDs({ dealer_id: this.props.dealer.id })
        .then(response => {
          const planitPlanningEstablishmentOptions = response?.data?.data?.length
            ? response.data.data.map(d => ({
                text: d.DealerLocationID ? `${d.Name} - ${this.props.t("assigned").message || "assigned"}` : d.Name,
                key: d.Id,
                value: d.Id,
                disabled: d.DealerLocationID && d.DealerLocationID !== this.props.location.id,
              }))
            : [];

          this.setState({ planitPlanningEstablishmentOptions, loadingPlanitPlanningEstablishmentOptions: false });
        })
        .catch(error => {
          console.log(error);
          this.setState({ loadingPlanitPlanningEstablishmentOptions: false });
        });
    });
  };

  handleInputChange = e => {
    let { location, handleChange } = this.props;
    if (Object.keys(location).length === 0) return;
    if (!e.target.name.startsWith("number") && e.target.type !== "text") {
      location[e.target.name] = parseFloat(e.target.value);
    } else {
      location[e.target.name] = e.target.value;
    }

    const { allowSave, ...validationValues } = this.validatevalidationValues();

    this.setState({ ...validationValues }, () => {
      handleChange(location, allowSave);
    });
  };

  validatevalidationValues = () => {
    const { location } = this.props;

    let allSeasonThresholdError;
    let summerThresholdError;
    let winterThresholdError;
    let truckTyreThresholdError;
    let allowSave;

    if (location.all_season_n_threshold > location.all_season_a_threshold) {
      allSeasonThresholdError = true;
      allowSave = false;
    }
    if (location.summer_n_threshold > location.summer_a_threshold) {
      summerThresholdError = true;
      allowSave = false;
    }
    if (location.winter_n_threshold > location.winter_a_threshold) {
      winterThresholdError = true;
      allowSave = false;
    }

    if (location.truck_tyre_n_threshold > location.truck_tyre_a_threshold) {
      truckTyreThresholdError = true;
      allowSave = false;
    }

    return {
      allSeasonThresholdError,
      summerThresholdError,
      winterThresholdError,
      truckTyreThresholdError,
      allowSave,
    };
  };

  handleCheckboxChange = (e, data) => {
    let { location, handleChange } = this.props;

    location[data.name] = data.checked;
    handleChange(location);
  };

  handleChangePlanitPlanningEstablishmentID = (_, { value }) => {
    const { location, handleChange } = this.props;

    location["planit_planning_establishment_id"] = value || null;
    handleChange(location);
  };

  render() {
    const { dealer, location, user, t } = this.props;

    return (
      location && (
        <div>
          <MCCConfiguration location={location} onLocationChange={this.props.handleChange} readOnly={!isAdmin(user.role_id)} />
          <Segment>
            <Header as="h5">
              <Icon name="road" />
              <Header.Content>{t("all_season_tyre_settings").message || "All Season tyre settings"}</Header.Content>
            </Header>
            <Segment>
              <Form>
                <Form.Field className="full-width-input">
                  <label>{t("all_season_a_threshold").message || "All Season A Threshold"}</label>
                  <UserInput
                    value={location.all_season_a_threshold}
                    placeholder={t("all_season_a_threshold").message || "All Season A Threshold"}
                    name="all_season_a_threshold"
                    type="number"
                    step="0.1"
                    min="0"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field className="full-width-input">
                  <label>{t("all_season_b_threshold").message || "All Season B Threshold"}</label>
                  <UserInput
                    value={location.all_season_n_threshold}
                    placeholder={t("all_season_b_threshold").message || "All Season B Threshold"}
                    name="all_season_n_threshold"
                    type="number"
                    min="0"
                    step="0.1"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>

                {this.state.allSeasonThresholdError && (
                  <Form.Field>
                    <Label color="red">{t("threshold_validation").message || "Threshold N can't be bigger than threshold A"}</Label>
                  </Form.Field>
                )}
              </Form>
            </Segment>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="sun outline" />
              <Header.Content>{t("summer_tyre_settings").message || "Summer tyre settings"}</Header.Content>
            </Header>
            <Segment>
              <Form>
                <Form.Field className="full-width-input">
                  <label>{t("summer_a_threshold").message || "Summer A Threshold"}</label>
                  <UserInput
                    value={location.summer_a_threshold}
                    placeholder={t("summer_a_threshold").message || "Summer A Threshold"}
                    name="summer_a_threshold"
                    type="number"
                    step="0.1"
                    min="0"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field className="full-width-input">
                  <label>{t("summer_b_threshold").message || "Summer B Threshold"}</label>
                  <UserInput
                    value={location.summer_n_threshold}
                    placeholder={t("summer_b_threshold").message || "Summer B Threshold"}
                    name="summer_n_threshold"
                    type="number"
                    step="0.1"
                    min="0"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>

                {this.state.summerThresholdError && (
                  <Form.Field>
                    <Label color="red">{t("threshold_validation").message || "Threshold N can't be bigger than threshold A"}</Label>
                  </Form.Field>
                )}
              </Form>
            </Segment>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="snowflake" />
              <Header.Content>{t("winter_tyre_settings").message || "Winter tyre settings"}</Header.Content>
            </Header>
            <Segment>
              <Form>
                <Form.Field className="full-width-input">
                  <label>{t("winter_a_threshold").message || "Winter A Threshold"}</label>
                  <UserInput
                    value={location.winter_a_threshold}
                    placeholder={t("winter_a_threshold").message || "Winter A Threshold"}
                    name="winter_a_threshold"
                    type="number"
                    min="0"
                    step="0.1"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field className="full-width-input">
                  <label>{t("winter_b_threshold").message || "Winter B Threshold"}</label>
                  <UserInput
                    value={location.winter_n_threshold}
                    placeholder={t("winter_b_threshold").message || "Winter B Threshold"}
                    name="winter_n_threshold"
                    type="number"
                    step="0.1"
                    min="0"
                    onChange={this.handleInputChange}
                  />
                </Form.Field>

                {this.state.winterThresholdError && (
                  <Form.Field>
                    <Label color="red">{t("threshold_validation").message || "Threshold N can't be bigger than threshold A"}</Label>
                  </Form.Field>
                )}
              </Form>
            </Segment>
          </Segment>
          <Segment as="h5">
            <Form>
              <Form.Field className="full-width-input">
                <label>{t("truck_tyre_a_threshold").message || "Truck Tyre A Threshold"}</label>
                <UserInput
                  value={location.truck_tyre_a_threshold}
                  placeholder={t("truck_tyre_a_threshold").message || "Truck Tyre A Threshold"}
                  name="truck_tyre_a_threshold"
                  type="number"
                  min="0"
                  step="0.1"
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field className="full-width-input">
                <label>{t("truck_tyre_n_threshold").message || "Truck Tyre N Threshold"}</label>
                <UserInput
                  value={location.truck_tyre_n_threshold}
                  placeholder={t("truck_tyre_n_threshold").message || "Truck Tyre N Threshold"}
                  name="truck_tyre_n_threshold"
                  type="number"
                  step="0.1"
                  min="0"
                  onChange={this.handleInputChange}
                />
              </Form.Field>

              {this.state.truckTyreThresholdError && (
                <Form.Field>
                  <Label color="red">{t("threshold_validation").message || "Threshold N can't be bigger than threshold A"}</Label>
                </Form.Field>
              )}
            </Form>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="file alternate outline" />
              <Header.Content>{t("lease").message || "Lease"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form>
                <Form.Field style={{ marginLeft: "4px", marginRight: 0, maxWidth: "100%" }}>
                  <Checkbox
                    checked={location.default_customer_ok_for_dms_lease_intervention}
                    name="default_customer_ok_for_dms_lease_intervention"
                    label={t("default_customer_ok_for_dms_lease_intervention").message || "Customer OK by default for DMS lease interventions"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
              </Form>
            </Segment.Inline>
          </Segment>

          {dealer.planit_planning_enabled && (
            <PlanitConfiguration
              location={location}
              loadingPlanitPlanningEstablishmentOptions={this.state.loadingPlanitPlanningEstablishmentOptions}
              planitPlanningEstablishmentOptions={this.state.planitPlanningEstablishmentOptions}
              onCheckboxChange={this.handleCheckboxChange}
              onChangePlanitPlanningEstablishmentID={this.handleChangePlanitPlanningEstablishmentID}
            />
          )}

          {location.dms_id === DMS.TRUCK_VISION && (
            <Segment>
              <Header as="h5">
                <Icon name="file alternate outline" />
                <Header.Content>TruckVision WebClock</Header.Content>
              </Header>
              <Segment.Inline>
                <Form>
                  <Form.Field className="full-width-input">
                    <label>Omgeving ID</label>
                    <UserInput
                      value={location.web_clock_omgeving_id}
                      placeholder="Omgeving ID"
                      name="web_clock_omgeving_id"
                      type="number"
                      min="1"
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </Form>
              </Segment.Inline>
            </Segment>
          )}
        </div>
      )
    );
  }
}

export default DetailSettings;
