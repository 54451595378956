import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Form, Dropdown, Button, Checkbox, Label, Divider } from "semantic-ui-react";
import moment from "moment";
import DatePicker from "react-datepicker";

import { UserInput } from "../../../../components";
import { Can } from "../../../";

import { parseDate, DMS, getDMSName } from "../../util";

const config = {
  dealer_id: null,
  dms_id: null,
  time_to_sleep: "",
  date_range: "",
  start_time: "",
  end_time: "",
  duration_threshold: "",
  dsn: "",
  is_interval_full_run_enabled: false,
  full_run_hour_interval: "",
  delay: "",
  is_cron: false,
  import_date_created: true,
  is_custom_query_only: false,
  icar_config: {
    dms_dealer_id: "",
    dms_customer_waiting_id: "",
  },
  incadea_config: {
    dms_dealer_id: "",
    dms_appointment_type: "",
    dms_warranty_intervention_id: "",
    dms_internal_intervention_id: "",
  },
  keyloop_config: {},
  autoline_config: {
    business_customer_table_id: "10",
    customer_table_id: "00",
    car_table_id: "00",
    customer_detail_table_id: "NL_00",
    car_make_table_id: "00",
    car_note_table_id: "00",
    department_id: "",
    vat_table_id: "10",
    price_includes_vat: "s",
    rental_billed: "X",
    rental_code: "HUURK",
    rental_status: "X",
  },
  eva_config: {
    dms_lease_customer_ids: "",
    dms_lease_customer_category_ids: "",
  },
  wincar_config: {
    dms_lease_customer_ids: "",
    client_id: "",
    api_id: "",
    access_token: "",
    api_base_url: "",
  },
  carit_config: {
    import_offers: true,
    internal_orders: true,
    internal_customer_group_ids: "4",
    api_base_url: "",
    user_name: "",
    password: "",
  },
};

class DealerDetail extends Component {
  state = { dealerConfiguration: this.props.selectedDealer ? { ...this.props.selectedDealer } : config };

  handleDMSIDChange = (_e, { value: dms_id }) => {
    this.setState(
      ({ dealerConfiguration }) => ({
        dealerConfiguration: { ...dealerConfiguration, dms_id },
      }),
      () => {
        const autoline_config = { ...this.state.dealerConfiguration.autoline_config };

        if ([DMS.AUTOLINE_EF_NEFKENS, DMS.AUTOLINE_OPEL_MENU].includes(dms_id)) autoline_config.department_id = "W";
        else autoline_config.department_id = this.props.selectedDealer?.autoline_config.department_id || "";

        this.setState(({ dealerConfiguration }) => ({ dealerConfiguration: { ...dealerConfiguration, autoline_config } }));
      }
    );
  };

  handleInputChange = e => {
    let { name, value } = e.target;

    if (name === "full_run_hour_interval" && Number(value) <= 0) value = "";

    this.setState(({ dealerConfiguration }) => ({
      dealerConfiguration: { ...dealerConfiguration, [name]: value },
    }));
  };

  handleSubFieldInputChange = (_e, { name, value, subfield }) => {
    this.setState(prevState => ({
      dealerConfiguration: {
        ...prevState.dealerConfiguration,
        [subfield]: {
          ...prevState.dealerConfiguration[subfield],
          [name]: value,
        },
      },
    }));
  };

  handleSubFieldCheckboxChange = (_e, { name, checked, subfield }) => {
    this.setState(prevState => ({
      dealerConfiguration: {
        ...prevState.dealerConfiguration,
        [subfield]: {
          ...prevState.dealerConfiguration[subfield],
          [name]: checked,
        },
      },
    }));
  };

  handleChangeDropdown = (e, { name, value }) => {
    this.setState(({ dealerConfiguration }) => ({
      dealerConfiguration: { ...dealerConfiguration, [name]: value },
    }));
  };

  handleCheckboxChange = (_, { name, checked }) => {
    const { dealerConfiguration } = this.state;

    if (name === "is_interval_full_run_enabled" && !checked) dealerConfiguration.full_run_hour_interval = "";

    this.setState({ dealerConfiguration: { ...dealerConfiguration, [name]: checked } });
  };

  handleChangeStartTime = start_time => {
    this.setState(({ dealerConfiguration }) => ({
      dealerConfiguration: { ...dealerConfiguration, start_time },
    }));
  };

  handleChangeEndTime = end_time => {
    this.setState(({ dealerConfiguration }) => ({
      dealerConfiguration: { ...dealerConfiguration, end_time },
    }));
  };

  prepareDMSDropdownDealerDetail = () => {
    const { dealerConfiguration } = this.state;
    const { dealers, allDealers } = this.props;

    const dmsOptions = [];

    Object.keys(DMS).forEach(key => {
      if (DMS[key] > DMS.UNKNOWN) {
        let dmsConfigured;

        dmsConfigured = allDealers.some(dealer => dealer.id === dealerConfiguration?.dealer_id && dealer.dms_id === DMS[key] && DMS[key] !== DMS.UNKNOWN);
        dmsConfigured = dealers.some(dealer => dealer.dealer_id === dealerConfiguration?.dealer_id && dealer.dms_id === DMS[key] && DMS[key] !== DMS.UNKNOWN);

        dmsOptions.push({
          key: DMS[key],
          text: dmsConfigured ? `${getDMSName(DMS[key])} - ${this.props.t("configured").message || "configured"}` : getDMSName(DMS[key]),
          value: DMS[key],
          disabled: dmsConfigured,
        });
      }
    });

    return dmsOptions;
  };

  renderDMSConfig = () => {
    const { dealerConfiguration } = this.state;
    const { dealer_id, dms_id, incadea_config, icar_config, carit_config, eva_config, autoline_config, wincar_config } = dealerConfiguration;
    const { t } = this.props;

    switch (dms_id) {
      case DMS.INCADEA:
        return (
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("dms_dealer_id").message || "DMS Dealer ID"}</label>
              <UserInput
                value={incadea_config.dms_dealer_id}
                subfield="incadea_config"
                onChange={this.handleSubFieldInputChange}
                name="dms_dealer_id"
                disabled={!dealer_id}
              />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_appointment_type").message || "DMS Appointment Type"}</label>
              <UserInput value={incadea_config.dms_appointment_type} subfield="incadea_config" onChange={this.handleSubFieldInputChange} name="dms_appointment_type" />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_warranty_intervention_id").message || "DMS Warranty Intervention ID"}</label>
              <UserInput
                value={incadea_config.dms_warranty_intervention_id}
                subfield="incadea_config"
                onChange={this.handleSubFieldInputChange}
                name="dms_warranty_intervention_id"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_internal_intervention_id").message || "DMS Internal Intervention ID"}</label>
              <UserInput
                value={incadea_config.dms_internal_intervention_id}
                subfield="incadea_config"
                onChange={this.handleSubFieldInputChange}
                name="dms_internal_intervention_id"
              />
            </Form.Field>
          </Form.Group>
        );

      case DMS.ICAR_PLANNING:
      case DMS.ICAR_WO:
        return (
          <Form.Group widths="equal">
            <Form.Field className="full-width-input">
              <label>{t("dms_dealer_id").message || "DMS Dealer ID"}</label>
              <UserInput value={icar_config.dms_dealer_id} subfield="icar_config" onChange={this.handleSubFieldInputChange} name="dms_dealer_id" disabled={!dealer_id} />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_customer_waiting_id").message || "DMS Customer waiting ID"}</label>
              <UserInput value={icar_config.dms_customer_waiting_id} subfield="icar_config" onChange={this.handleSubFieldInputChange} name="dms_customer_waiting_id" />
            </Form.Field>
          </Form.Group>
        );

      case DMS.CARIT:
        return (
          <>
            <Form.Group>
              <Form.Field width={5}>
                <label>{t("internal_customer_group_ids").message || "Internal customer group ids"}</label>
                <UserInput
                  value={carit_config.internal_customer_group_ids}
                  subfield="carit_config"
                  onChange={this.handleSubFieldInputChange}
                  name="internal_customer_group_ids"
                />
              </Form.Field>

              <Form.Field width={11}>
                <label>{t("api_base_url").message || "API Base URL"}</label>
                <UserInput value={carit_config.api_base_url} subfield="carit_config" onChange={this.handleSubFieldInputChange} name="api_base_url" />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={5}>
                <label>{t("username").message || "Username"}</label>
                <UserInput value={carit_config.user_name} subfield="carit_config" onChange={this.handleSubFieldInputChange} name="user_name" />
              </Form.Field>

              <Form.Field width={5}>
                <label>{t("password").message || "Password"}</label>
                <UserInput value={carit_config.password} subfield="carit_config" onChange={this.handleSubFieldInputChange} name="password" />
              </Form.Field>

              <Form.Field width={2}>
                <label>{t("import_offers").message || "Import offers"}</label>
                <Checkbox toggle name="import_offers" subfield="carit_config" checked={carit_config.import_offers} onChange={this.handleSubFieldCheckboxChange} />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("import_internal_orders").message || "Import internal orders"}</label>
                <Checkbox toggle name="internal_orders" subfield="carit_config" checked={carit_config.internal_orders} onChange={this.handleSubFieldCheckboxChange} />
              </Form.Field>
            </Form.Group>
          </>
        );

      case DMS.EVA:
      case DMS.ANON_EVA:
      case DMS.EVA_BE:
        return (
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("dms_lease_customer_ids").message || "DMS Lease Customer ID"}</label>
              <UserInput value={eva_config.dms_lease_customer_ids} subfield="eva_config" onChange={this.handleSubFieldInputChange} name="dms_lease_customer_ids" />
            </Form.Field>

            <Form.Field>
              <label>{t("dms_lease_customer_category_ids").message || "DMS Lease Customer Category ID"}</label>
              <UserInput
                value={eva_config.dms_lease_customer_category_ids}
                subfield="eva_config"
                onChange={this.handleSubFieldInputChange}
                name="dms_lease_customer_category_ids"
              />
            </Form.Field>
          </Form.Group>
        );

      case DMS.AUTOLINE:
      case DMS.AUTOLINE_EF_NEFKENS:
      case DMS.AUTOLINE_OPEL_MENU:
      case DMS.AUTOLINE_EF_TERWOLDE:
        return (
          <>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("business_customer_table_id").message || "Business customer table ID"}</label>
                <UserInput
                  value={autoline_config.business_customer_table_id}
                  subfield="autoline_config"
                  onChange={this.handleSubFieldInputChange}
                  name="business_customer_table_id"
                />
              </Form.Field>

              <Form.Field>
                <label>{t("customer_table_id").message || "Customer table ID"}</label>
                <UserInput value={autoline_config.customer_table_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="customer_table_id" />
              </Form.Field>

              <Form.Field>
                <label>{t("car_table_id").message || "Car table ID"}</label>
                <UserInput value={autoline_config.car_table_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="car_table_id" />
              </Form.Field>

              <Form.Field>
                <label>{t("customer_detail_table_id").message || "Customer detail table ID"}</label>
                <UserInput
                  value={autoline_config.customer_detail_table_id}
                  subfield="autoline_config"
                  onChange={this.handleSubFieldInputChange}
                  name="customer_detail_table_id"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field width={3}>
                <label>{t("car_make_table_id").message || "Car make table ID"}</label>
                <UserInput value={autoline_config.car_make_table_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="car_make_table_id" />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("car_note_table_id").message || "Car note table ID"}</label>
                <UserInput value={autoline_config.car_note_table_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="car_note_table_id" />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("department_id").message || "Department ID"}</label>
                <UserInput value={autoline_config.department_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="department_id" />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("vat_table_id").message || "VAT Table ID"}</label>
                <UserInput value={autoline_config.vat_table_id} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="vat_table_id" />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("price_includes_vat").message || "Price includes VAT"}</label>
                <UserInput value={autoline_config.price_includes_vat} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="price_includes_vat" />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("rental_billed").message || "Rental billed"}</label>
                <UserInput value={autoline_config.rental_billed} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="rental_billed" />
              </Form.Field>

              <Form.Field>
                <label>{t("rental_code").message || "Rental code"}</label>
                <UserInput value={autoline_config.rental_code} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="rental_code" />
              </Form.Field>

              <Form.Field>
                <label>{t("rental_status").message || "Rental status"}</label>
                <UserInput value={autoline_config.rental_status} subfield="autoline_config" onChange={this.handleSubFieldInputChange} name="rental_status" />
              </Form.Field>
            </Form.Group>
          </>
        );

      case DMS.WINCAR:
      case DMS.WINCAR_API:
        return (
          <>
            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("dms_lease_customer_ids").message || "DMS Lease Customer ID"}</label>
                <UserInput
                  value={wincar_config.dms_lease_customer_ids}
                  subfield="wincar_config"
                  onChange={this.handleSubFieldInputChange}
                  name="dms_lease_customer_ids"
                />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("client_id").message || "Client ID"}</label>
                <UserInput value={wincar_config.client_id} subfield="wincar_config" onChange={this.handleSubFieldInputChange} name="client_id" />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("api_id").message || "API ID"}</label>
                <UserInput value={wincar_config.api_id} subfield="wincar_config" onChange={this.handleSubFieldInputChange} name="api_id" />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("access_token").message || "Access Token"}</label>
                <UserInput value={wincar_config.access_token} subfield="wincar_config" onChange={this.handleSubFieldInputChange} name="access_token" />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("api_base_url").message || "API Base URL"}</label>
                <UserInput value={wincar_config.api_base_url} subfield="wincar_config" onChange={this.handleSubFieldInputChange} name="api_base_url" />
              </Form.Field>
            </Form.Group>
          </>
        );

      default:
        return null;
    }
  };

  render() {
    const { dealerConfiguration } = this.state;
    const {
      dealer_id,
      dms_id,
      time_to_sleep,
      date_range,
      dsn,
      start_time,
      end_time,
      duration_threshold,
      is_interval_full_run_enabled,
      full_run_hour_interval,
      delay,
      is_cron,
      import_date_created,
      is_custom_query_only,
    } = dealerConfiguration;
    const { isEditingDealer, dealersOptions, handleAddOrUpdateDealer, handleToggleAddOrEditDealer, handleDeleteDealer, selectedDealer, showValidationError, t } =
      this.props;

    return (
      <Modal open={true} closeOnDimmerClick={false}>
        <Modal.Header>
          <div style={{ display: "inline-block" }}>{isEditingDealer ? t("edit_dealer").message || "Edit Dealer" : t("add_dealer").message || "Add Dealer"}</div>
        </Modal.Header>

        <Modal.Content scrolling className="DMSv3DealerDetail">
          <Form>
            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("select_dealer").message || "Select Dealer"}*</label>
                <Dropdown
                  options={dealersOptions}
                  value={dealer_id}
                  name="dealer_id"
                  onChange={this.handleChangeDropdown}
                  selection
                  disabled={isEditingDealer}
                  selectOnBlur={false}
                  clearable
                  search
                />
                {!dealer_id && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("select_dms").message || "Select DMS"}*</label>
                <Dropdown
                  options={this.prepareDMSDropdownDealerDetail()}
                  value={dms_id}
                  name="dms_id"
                  onChange={this.handleDMSIDChange}
                  selection
                  selectOnBlur={false}
                  disabled={!dealer_id || isEditingDealer}
                  clearable
                />
                {!dms_id && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8}>
                <label>{t("enter_dsn").message || "Enter DSN"}</label>
                <UserInput value={dsn} onChange={this.handleInputChange} name="dsn" disabled={!dealer_id} />
              </Form.Field>

              <Form.Field width={4}>
                <label>{t("time_to_sleep").message || "Time to sleep"} (m)*</label>
                <UserInput value={time_to_sleep} onChange={this.handleInputChange} name="time_to_sleep" min={0} type="number" disabled={!dealer_id} />
                {!Number(time_to_sleep) && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field width={4}>
                <label>{`${this.props.t("date_range").message || "Date range"} (${this.props.t("days").message || "days"})*`}</label>
                <UserInput value={date_range} onChange={this.handleInputChange} name="date_range" min={0} type="number" disabled={!dealer_id} />
                {!Number(date_range) && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field className="full-width-input">
                <label>{t("start_time").message || "Start time"}*</label>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  selected={parseDate(start_time)}
                  onChange={this.handleChangeStartTime}
                  name="start_time"
                  disabled={!dealer_id}
                />
                {!start_time && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("end_time").message || "End time"}*</label>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  selected={parseDate(end_time)}
                  minTime={moment(start_time).add(30, "m").toDate()}
                  maxTime={new Date().setHours(23, 30)}
                  onChange={this.handleChangeEndTime}
                  name="end_time"
                  disabled={!dealer_id}
                />
                {!end_time && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("duration_threshold").message || "Duration Threshold"} (s)*</label>
                <UserInput value={duration_threshold} onChange={this.handleInputChange} name="duration_threshold" min={0} type="number" disabled={!dealer_id} />
                {!Number(duration_threshold) && showValidationError && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("is_interval_full_run_enabled").message || "Enable Full Run Interval"}</label>
                <Checkbox toggle name="is_interval_full_run_enabled" checked={is_interval_full_run_enabled} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field className="full-width-input">
                <label>{t("full_run_hour_interval").message || "Full Run Hour Interval"}</label>
                <UserInput
                  type="number"
                  value={full_run_hour_interval}
                  onChange={this.handleInputChange}
                  name="full_run_hour_interval"
                  disabled={!is_interval_full_run_enabled}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={3}>
                <label>{t("delay").message || "Delay"}</label>
                <UserInput value={delay} onChange={this.handleInputChange} name="delay" />
              </Form.Field>

              <Form.Field width={2}>
                <label>{t("is_cron").message || "Is Cron"}</label>
                <Checkbox disabled={isEditingDealer} toggle name="is_cron" checked={is_cron} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("import_date_created").message || "Import date created"}</label>
                <Checkbox toggle name="import_date_created" checked={import_date_created} onChange={this.handleCheckboxChange} />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("custom_query_only").message || "Custom Query only"}</label>
                <Checkbox toggle name="is_custom_query_only" checked={is_custom_query_only} onChange={this.handleCheckboxChange} />
              </Form.Field>
            </Form.Group>

            <Divider />

            {this.renderDMSConfig()}
          </Form>

          {selectedDealer && <h4>DMSv3 key: {selectedDealer.dmsv3_key}</h4>}
        </Modal.Content>

        <Modal.Actions>
          <Button color="grey" onClick={() => handleToggleAddOrEditDealer(null)}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Can I={["add", "update"]} the="dmsv3-setting">
            <Button color="green" onClick={() => handleAddOrUpdateDealer(dealerConfiguration)}>
              {t("save").message || "Save"}
            </Button>
          </Can>
          {isEditingDealer && (
            <Can I="delete" the="dmsv3-setting">
              <Button color="red" floated="left" onClick={() => handleDeleteDealer({ dealer_id, dms_id })}>
                {t("delete").message || "Delete"}
              </Button>
            </Can>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(DealerDetail);
